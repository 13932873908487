import React from 'react';
import { CookiesProvider } from 'react-cookie';

import './src/assets/interface.scss';

export const wrapRootElement = ({ element }) => (
  <CookiesProvider>{element}</CookiesProvider>
);

export const onClientEntry = () => {
  const loader = window.___loader;

  // if development or no loader exists we shouldn't do anything
  if (process.env.NODE_ENV === 'development' || !loader) {
    return;
  }

  const pagePath = window.pagePath;
  const location = window.location;

  if (
    pagePath &&
    pagePath !== location.pathname &&
    pagePath !== location.pathname + '/'
  ) {
    const originalLoadPageSync = loader.loadPageSync;
    const originalLoadPage = loader.loadPage;

    loader.loadPageSync = path => {
      let pageResources;
      // if the path is the same as our current page we know it's not a prefetch
      if (path === location.pathname) {
        pageResources = originalLoadPageSync(pagePath);
      } else {
        pageResources = originalLoadPageSync(path);
      }

      if (pageResources.page) {
        pageResources.page.matchPath = '*';
      }

      return pageResources;
    };

    loader.loadPage = path => {
      let pageResources;
      // if the path is the same as our current page we know it's not a prefetch
      if (path === location.pathname) {
        pageResources = originalLoadPage(pagePath);
      } else {
        pageResources = originalLoadPage(path);
      }

      if (pageResources.page) {
        pageResources.page.matchPath = '*';
      }

      return pageResources;
    };
  }

  // disable hovering prefetching as we don't know if we can.
  loader.hovering = () => {};
};

// we also need to disable prefetching as we don't know the exact page-data path.
// TODO look at prefetch a whole html page on hover?
export const disableCorePrefetching = () => true;

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null;
  }

  // const { query, hash } = location;
  // if (query && query.eapp_id) {
  //   console.log('Doing re-direct for eapp_id');
  //   console.log('Hash: ', hash);
  //   const pathPath = `${location.pathname}/?eapp_id=${
  //     query.eapp_id
  //   }&eapp_uuid=${query.eapp_uuid}${hash && hash !== '' ? hash : ''}`;
  //   window.location = pathPath;
  // }

  // window.page.path = window.location.pathname;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  const delayOnRouteUpdate = 0;

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, delayOnRouteUpdate));
    });
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate);
  }

  return null;
};
