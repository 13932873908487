module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fixhash/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":146,"scrollToOptions":{"behavior":"smooth"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lsnb.ctbstaging.live","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/joshuat/Projects/Websites/Lone Star National Bank/lsnb-website/locales","languages":["en","es"],"defaultLanguage":"en","redirect":false,"i18nextOptions":{"ns":["Banking","findLocation","Footer","global","HomePage","LoginModal","mortgagePage","Styleguide","TeamMemberSlider","TopBar"],"defaultNS":"global","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lone Star National Bank","short_name":"LSNB","start_url":"/","background_color":"#2174ff","theme_color":"#fff","display":"standalone","icon":"src/assets/images/global/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4373b2ed2c8f21ab3ccdcba34c1b45e4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
